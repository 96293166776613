@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --NavWrapper-width: 250px;
  --NavWrapper2-width: 200px;
}

/* * {
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(255, 0, 0);
} */

.text-danger {
  font-size: 12px;
  font-weight: 600;
}
.iconnotifikasi {
  width: 24px;
  height: 24px;
}
.Tabs-container {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
}

.Tab-1-unactive,
.Tab-2-unactive,
.Tab-3-unactive {
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  color: black;
  font-weight: 600;
}

.Tab-1-active,
.Tab-2-active,
.Tab-3-active {
  text-align: center;
  padding-top: 10px;
  color: #1b5de0;
  font-weight: 600;
}
.Line-tab-active {
  margin-top: 10px;
  height: 2px;
  width: 100%;
  background-color: #1b5de0;
}

.Line-tab-unactive {
  margin-top: 10px;
  height: 2px;
  width: 100%;
  background-color: #ffffff;
}

.Content-tabs-Container {
  margin-top: 20px;
}
.radio-wrapper {
  display: flex;
  width: 100%;
}

.wrapper-image-point {
  display: flex;
  position: absolute;
  z-index: 1000;
  left: 45%;
  top: 90%;
}

.image-point {
  background-color: rgb(255, 255, 255);
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin-left: 6px;
  margin-right: 6px;
}

.image-point-active {
  background-color: rgb(255, 166, 0);
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin-left: 6px;
  margin-right: 6px;
}

.backgroundAppV2 {
  background-color: #ffffff;
}
.Content {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding-bottom: 20px;
}
.left-content {
  margin-right: 10px;
}
.right-content {
  margin-left: 10px;
}

.wrapper-konten {
  display: flex;
  width: 100%;
}

.slider-images {
}
.images {
  width: 100%;
  object-fit: fill;
}
.slider-wrapper {
  width: 750px;
  height: 296px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background: #1b5de0;
}

.text-urut1-berjalan {
  font-size: 14px;
  font-weight: 700;
}
.text-urut2-berjalan {
  font-size: 14px;
  font-weight: 600;
}
.text-urut3-berjalan {
  font-size: 70px;
  font-weight: 600;
}
.Error-img {
  width: 400px;
  height: 400px;
}
.Error-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
}

.notif-container {
  margin-top: 20px;
}

.formDate {
  width: 100%;
  padding: 6px;
  border-radius: 6px;
  padding-left: 14px;
  padding-right: 420px;
  outline-color: gray;
}

/*Login CSS*/

.BackrgoundLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("/src/assets/bg_form.JPG");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -700px;
  filter: hue-rotate(0deg);
}

.BackrgoundOverlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(12, 38, 205, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.WrapperForm {
  padding-top: 200px;
}

.FormWrapper {
  background-color: white;
  width: 800px;
  padding: 20px;
  border-radius: 6px;
  position: absolute;
}

.FormWrapper2 {
  background-color: white;
  width: 450px;
  padding: 20px;
  border-radius: 6px;
  position: absolute;
}

.FormHeader {
  text-align: center;
  margin-bottom: 20px;
}

.LogoBadak {
  width: 100px;
  height: 100px;
}

.container-button-main {
  width: 100%;
  display: flex;
  justify-content: end;
}

.LoginButton {
  margin-top: 20px;
  width: 100%;
}
.signOutButton,
.backButton {
  width: 100%;
}

.backButton Button {
  width: 100%;
}

.signOutButton Button {
  width: 100%;
  background-color: #1b5de0;
  color: white;
}

.InputForm {
  margin-top: 20px;
}

.form {
  padding-bottom: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.CheckSan {
  margin-top: 4px;
}

.checkbox {
  font-size: 12px;
}

.LoginButton Button {
  width: 100%;
  background-color: #1b5de0;
  color: white;
}

.popupButton Button {
  background-color: #1b5de0;
  color: white;
}

.LoginButton Button:hover {
  background-color: #1b5de0;
  color: white;
}

.LupaKataSandi {
  color: #1b5de0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
  text-decoration: none;
}

.BuatSekarang {
  color: #1b5de0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: right;
  text-decoration: none;
}

.BuatAkun {
  text-align: center;
  margin-top: 40px;
}

.Login-sekarang {
  text-align: center;
  margin-top: 20px;
}
.eye {
  cursor: pointer;
}

/*Make Account CSS*/

.BackrgoundMakeAccount {
  background-color: #1b5de0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.form {
  margin-top: 4px;
}

.FormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.GambarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.GambarAnimasi {
  width: 600px;
  height: 600px;
}

.RowLogin {
  height: 100vh;
}

/*Main page*/

/* .MainBG {
  background-color: #d9e492;
  height: 100vh;
} */

.NavConatiner {
  background-color: #1b5de0;
  width: var(--NavWrapper-width);
  position: fixed;
}

.NavWrapper {
  height: 100vh;
  padding-left: 30px;
  padding-right: 30px;

  /* margin-left: 30px;
  margin-right: 30px; */
}

.HeaderNavbar {
  padding-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  color: white;
}

.BadakImgNavbar {
  width: 40px;
}

.HeaderText {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.Nav {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.listNav {
  list-style: none;
}

.NavText {
  padding: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  align-items: center;
  display: flex;
  color: white;
}

.NavIconWrapper:hover {
  background-color: rgba(255, 254, 254, 0.3);
  border-radius: 10px;
  cursor: pointer;
}
.active {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.NavIcon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

/*Halaman SIM & SKCK*/
.AlurWrapper {
  width: 95%;
  height: 95%;
}

.Row1 {
  display: flex;
  width: 100%;
}

.TittleSection {
  display: flex;
  color: black;
  cursor: pointer;
}

/*mainpage*/

/* .bodyMainPage {
  width: 100%;
  background-color: #ffffff;
} */

.MainWrapper {
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-columns: var(--NavWrapper-width) 1fr;
}

.RowMainpage {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.wrapper-menu {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vertical-line {
  margin-left: 30px;
}

.horizontal-line {
  border-top: 1px solid #1b5de0;
  margin-top: 30px;
  width: 100%;
}
.antrian-container {
  display: flex;
  justify-content: space-between;
}
.section-antrian {
  display: flex;
}
.container-content {
  background-color: rgb(255, 255, 255);
  padding: 0px 40px 0px 40px;
  width: 100%;
}
.container-content-v2 {
  background-color: rgb(255, 255, 255);
  padding: 0px 40px 0px 40px;
  width: 100%;
}

.Row-Antrian {
  width: 100%;
  display: flex;
}
.Logo-Antrian {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.No-urut-antrian {
  position: absolute;
  left: 1200;
  top: 400;
  font-size: 100px;
}
.No-urut-antrian-Skck {
  position: absolute;
  left: 1400;
  top: 400;
  font-size: 100px;
}
.Antrian {
  background-color: #d9d9d9;
  height: 224px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.Antrian-user {
  background-color: #d9d9d9;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.Antrian-berjalan-user {
  background-color: #d9d9d9;
  height: 224px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.container-antrian-sim {
  height: 224px;
  width: 100%;
}

.MenuSectionText {
  margin-top: 20px;
  margin-bottom: 20px;
}

.UserTextWrapper {
}

.GreetingText {
  padding-top: 20px;
  margin-bottom: 20px;
}

.UserText {
}

.TextMenu {
  color: black;
  margin-top: 10px;
  font-size: 16px;
}

.boximage {
  background-color: #1b5de0;
  width: fit-content;

  border-radius: 20px;
}

.MenuIconWrapper {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100px;
  cursor: pointer;
}

.MenuIconWrapperCall {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tooltips {
  background: #1b5de0;
  position: absolute;
  width: 330px;
  top: 386px;
  right: 50px;
  padding: 6px;
  border-radius: 5px;
  color: #ffffff;
  z-index: 999;
  visibility: hidden;
}

.tooltips::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #1b5de0;
  bottom: 0;
  left: 87%;
  transform: rotate(45deg) translateY(50%);
}

.menucall:hover + .tooltips {
  visibility: visible;
}

.iconAntrian {
  width: 28px;
  height: 28px;
}

/*navbar v2*/

.HeaderOffcanvas {
  background-color: #1b5de0;

  justify-content: center;
  align-items: center;
}

.HeaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BadakImgOffcanvas {
  height: 40px;
  width: 40px;
}

.HeaderTextOffCanvas {
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BurgerMenu-Btn {
  margin: 20px;
  padding: 6px;
  background-color: #1b5de0;
  outline-width: 3px;
  outline-style: solid;
  outline-color: rgb(255, 255, 255);
  border-radius: 4px;
}

.Nav-Item {
  padding-top: 6px;
  padding-bottom: 6px;
}

/* Survei */
.GambarErrorWrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.GambarError {
  width: 400px;
  height: 400px;
}

/* Profile */
.Wrapper-Gambar-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Profile-Wrapper {
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.Gambar-profile {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: darkgray;
  margin-bottom: 10px;
}

.Data-Alamat {
  margin-top: 10px;
}

/* notifikassi */

.Notifikasi {
  width: 100%;
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(151, 151, 151);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-notifikasi {
  padding: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: black;
}
.arrow-wrapper {
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Time-notification {
  font-size: 14px;
}

/*survei*/
.btn-survei {
  width: 100%;
  background-color: #1b5de0;
  color: white;
}

.btn-survei-container {
  width: 100%;
  background-color: #1b5de0;
  border-radius: 8px;
}

/*Notifikasi*/
.text-urut-wrapper-antrian-user {
  position: absolute;
  text-align: center;
}
.text-urut1 {
  font-size: 20px;
  font-weight: 700;
}
.text-urut2 {
  font-size: 20px;
  font-weight: 600;
}
.text-urut3 {
  font-size: 70px;
  font-weight: 600;
}

#brand-mobile {
  display: none;
}

#responsiv-ipad {
  display: none;
}

.Pannic-Button {
  width: 100%;
  background-color: #1b5de0;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  border: 0;
}

#show-telepon-button {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .slider-wrapper {
    width: 720px;
    height: 296px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
}

@media only screen and (max-width: 1480px) {
  .slider-wrapper {
    width: 700px;
    height: 296px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
}

@media only screen and (max-width: 1450px) {
  .slider-wrapper {
    width: 660px;
    height: 296px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
}

@media only screen and (max-width: 1405px) {
  .slider-wrapper {
    width: 620px;
    height: 296px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
}

@media only screen and (max-width: 1370px) {
  .slider-wrapper {
    width: 580px;
    height: 290px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .Antrian-berjalan-user {
    background-color: #d9d9d9;
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1330px) {
  .slider-wrapper {
    width: 565px;
    height: 275px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .Antrian-berjalan-user {
    background-color: #d9d9d9;
    height: 205px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1300px) {
  .MenuIcon {
    width: 60px;
    height: 60px;
  }

  .TextMenu {
    color: black;
    margin-top: 10px;
    font-size: 14px;
  }

  .MenuIconWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100px;
    cursor: pointer;
  }

  .text-urut1-berjalan {
    font-size: 12px;
    font-weight: 700;
  }
  .text-urut2-berjalan {
    font-size: 12px;
    font-weight: 600;
  }
  .text-urut3-berjalan {
    font-size: 50px;
    font-weight: 600;
  }
  .images {
    object-fit: cover;
  }

  .slider-wrapper {
    width: 560px;
    height: 260px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .Antrian-berjalan-user {
    background-color: #d9d9d9;
    height: 188px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .wrapper-image-point {
    display: flex;
    position: absolute;
    z-index: 1000;
    left: 43%;
    top: 90%;
  }

  .tooltips {
    background: #1b5de0;
    position: absolute;
    width: 330px;
    top: 350px;
    right: 50px;
    padding: 6px;
    border-radius: 5px;
    color: #ffffff;
    z-index: 999;
    visibility: hidden;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1250px) {
  .images {
    object-fit: cover;
  }

  .slider-wrapper {
    width: 540px;
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .Antrian-berjalan-user {
    background-color: #d9d9d9;
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .text-urut1-berjalan {
    font-size: 11px;
    font-weight: 700;
  }
  .text-urut2-berjalan {
    font-size: 11px;
    font-weight: 600;
  }
  .text-urut3-berjalan {
    font-size: 40px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tooltips {
    background: #1b5de0;
    position: absolute;
    width: 330px;
    top: 324px;
    right: 50px;
    padding: 6px;
    border-radius: 5px;
    color: #ffffff;
    z-index: 999;
    visibility: hidden;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1220px) {
  .wrapper-image-point {
    display: flex;
    position: absolute;
    z-index: 1000;
    left: 43%;
    top: 90%;
  }

  .slider-wrapper {
    width: 500px;
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .Antrian-berjalan-user {
    background-color: #d9d9d9;
    height: 178px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .text-urut1-berjalan {
    font-size: 11px;
    font-weight: 700;
  }
  .text-urut2-berjalan {
    font-size: 11px;
    font-weight: 600;
  }
  .text-urut3-berjalan {
    font-size: 40px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1170px) {
  .NavConatiner {
    background-color: #1b5de0;
    width: var(--NavWrapper2-width);
    position: fixed;
  }

  .MainWrapper {
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: var(--NavWrapper2-width) 1fr;
  }
}

@media only screen and (max-width: 1125px) {
  .slider-wrapper {
    width: 100%;
    height: 232px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .wrapper-menu {
    display: flex;
    flex-wrap: wrap;
  }

  .MenuIconWrapper {
    width: 25%;
    margin-bottom: 20px;
  }

  #box-antrian {
    display: none;
  }
  #responsiv-ipad {
    display: inherit;
  }
  #show-telepon {
    display: none;
  }

  .Content {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
  }

  .right-content {
    margin-left: 0px;
  }
  #show-telepon-button {
    display: inherit;
  }
}
@media only screen and (max-width: 980px) {
  .NavConatiner {
    background-color: #1b5de0;
    width: var(--NavWrapper2-width);
    position: fixed;
  }

  .MainWrapper {
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: var(--NavWrapper2-width) 1fr;
  }
}

@media only screen and (max-width: 740px) {
  .slider-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
}
@media only screen and (max-width: 700px) {
  .MenuIconWrapper {
    margin-bottom: 0px;
  }
  #responsiv-ipad {
    display: none;
  }
  #brand-mobile {
    display: flex;
  }
  #container1 {
    display: none;
  }

  #box-antrian {
    display: none;
  }
  #menuutama {
    display: none;
  }

  #wrappermenu {
    display: none;
  }
  #hr {
    display: none;
  }
  #navbar {
    display: none;
  }

  #sidebar {
    display: none;
  }

  /*Login Page*/
  .eye {
    cursor: pointer;
  }
  .FormWrapper2 {
    background-color: white;
    width: 370px;
    padding: 20px;
    border-radius: 4px;
  }

  /* Semua css di dalam blok ini akan diterapkan saat lebar layar perangkat berukuran di bawah atau sama dengan 960px */
  .GambarAnimasi {
    width: 200px;
    height: 200px;
  }

  .Row1 {
    /* width: 100%; */
  }

  /*Halaman SIM*/
  .AlurWrapper {
    width: 100%;
    height: 100%;
  }

  /*Main page*/
  .boximage {
    width: 100%;
    height: 150px;
  }

  .UserTextWrapper {
    margin-bottom: 20px;
    color: #ffffff;
  }

  .GreetingText {
    font-size: 16px;
    padding-top: 20px;

    margin-bottom: 0px;
    color: #ffffff;
  }

  .UserText {
    font-size: 16px;
    font-weight: 600;
  }
  .boxMobile {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 14px;
  }

  .MenuSectionText {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .TextMenu {
    font-size: 14px;
    margin-top: 6px;
  }

  .MenuIcon {
    height: 55px;
    width: 55px;
  }

  .MenuIconCall {
    height: 55px;
    width: 55px;
  }

  .bodyMainPage {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
  }

  .bodyMainPageV2 {
    width: 100%;
    background-color: #ffffff;
  }

  .wrapper-menu-mobile {
    background-color: #ffffff;
    border-radius: 14px 14px 0px 0px;
    padding-top: 20px;
    padding-bottom: 120px;
    margin-top: 20px;
  }

  .wrapper-menu {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  /* Survei */

  .GambarError {
    width: 300px;
    height: 300px;
  }

  .backgroundApp {
    background-color: #1b5de0;
  }
  .backgroundAppV2 {
    background-color: #ffffff;
  }

  .Upper-Menu {
    margin-bottom: 20px;
  }
  .Bottom-Menu {
    margin-bottom: 20px;
  }
  .NavMenuWrapper {
    color: black;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .NavMenuWrapperAktif {
    color: #1b5de0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-default {
    width: 26px;
    height: 26px;
  }
  .icon-active {
    width: 26px;
    height: 26px;
    filter: invert(29%) sepia(81%) saturate(1850%) hue-rotate(205deg)
      brightness(92%) contrast(92%);
  }

  .NavMobile {
    padding-top: 20px;
  }
  .NavigasiBottom {
    background-color: #ffffff;
    box-shadow: 0px -1px 30px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .Pannic-Button {
    width: 100%;
    background-color: #1b5de0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-weight: 600;
    border-radius: 6px;
    border: 0;
  }
  .Header-Wrapper-Mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1b5de0;
    padding-top: 12px;
    padding-bottom: 12px;
    gap: 4px;
    color: 0;
    top: 0;
    color: #ffffff;
  }
  .Header-Mobile {
    height: 40px;
    width: 40px;
  }
  .Header-Text-Mobile {
    font-size: 24px;
    font-weight: 600;
  }

  .Content {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .text-urut-wrapper {
    position: absolute;
    left: 18%;
    top: 38%;
    text-align: center;
  }
  .text-urut1 {
    font-size: 20px;
    font-weight: 700;
  }
  .text-urut2 {
    font-size: 20px;
    font-weight: 600;
  }
  .text-urut3 {
    font-size: 70px;
    font-weight: 600;
  }
  .Notifikasi {
    width: 100%;
    outline-width: 1px;
    outline-style: solid;
    outline-color: rgb(151, 151, 151);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-notifikasi {
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: black;
  }
  .Time-notification {
    font-size: 14px;
  }
  .Error-img {
    width: 300px;
    height: 300px;
  }
  .notif-container {
    margin-top: 10px;
  }
  .Size-tulisan-table {
    font-size: 14px;
  }
  .Row1 {
    display: contents;
    width: 100%;
  }

  .container-content {
    background-color: #1b5de0;
    padding: 0px 12px 0px 12px;
  }
  .container-content-v2 {
    background-color: #ffffff;
    padding: 0px 12px 100px 12px;
  }
  .container-menu-mobile {
    background-color: #fffcfc;
    padding: 0px 7px 0px 7px;
  }
  .wrapper-konten {
    display: contents;
  }
  .left-content {
    margin-right: 0px;
  }
  .right-content {
    margin-left: 0px;
  }

  .slider-images {
    display: flex;
    position: relative;
  }

  .slider-wrapper {
    width: 100%;
    height: 170px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin-top: 10px;
  }

  .wrapper-image-point {
    display: flex;
    position: absolute;
    z-index: 1000;
    left: 43%;
    top: 88%;
  }
  /* .wrapper-image-point {
    display: flex;
    position: absolute;
    z-index: 1000;
    left: 160px;
    top: 90%;
  } */

  .image-point {
    background-color: rgb(255, 255, 255);
    width: 8px;
    height: 8px;
    border-radius: 10px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .image-point-active {
    background-color: rgb(255, 166, 0);
    width: 8px;
    height: 8px;
    border-radius: 20px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .MainWrapper {
    background-color: rgb(255, 0, 0);
    display: flex;
    flex-direction: column;
  }
}
